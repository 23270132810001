import { useEffect, useState } from "react";
import ReactECharts from 'echarts-for-react';
import { API_URL } from "./Config";

const TimeTravelFlood = (props) => {
    

    useEffect(() => {
    /*   fetch(
        `${API_URL}wl/ffw`,
      )
      .then(response => response.json())
      .then(data => {setCompareStation(data)
        console.log(data);
    }) */
        
    }, []);

      const option = {
        title: {
          text: 'ระยะเวลาน้ำเดินทาง ฤดูฝน (ชม.)',
          textStyle: {
            fontSize: 18,
            fontFamily: 'Sarabun'
          },
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // Use axis to trigger tooltip
            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
          },textStyle: {
            fontSize: 16,
            fontFamily: 'Sarabun'
          },
        },
        legend: {
          textStyle: {
            fontSize: 12,
            fontFamily: 'Sarabun'
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          name: 'ชม.'
        },
        yAxis: {
          type: 'category',
          data: ['จิ่งหง','เชียงแสน','เชียงคาน','หนองคาย','นครพนม','มุกดาหาร','โขงเจียม']
        },
        series: [
          {
            name: 'จิ่งหง',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [null,18,58,68,126,142,179]
          },
          {
            name: 'เชียงแสน',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [18,null,40,50,108,124,161]
          },
          {
            name: 'เชียงคาน',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [58,40,null,10,68,84,121]
          },
          ,
          {
            name: 'หนองคาย',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [68,50,10,null,58,74,111]
          },
          {
            name: 'นครพนม',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [126,108,68,58,null,16,53]
          },
          {
            name: 'มุกดาหาร',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [142,124,84,74,16,null,37]
          },
          
          {
            name: 'โขงเจียม',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [179,161,121,111,53,37,null]
          },
        ]
      };
      return (<ReactECharts option={option} notMerge={true} lazyUpdate={true}  style={{height: '400px', width: '100%'}}/>)
}

export default TimeTravelFlood;