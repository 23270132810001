
import React, { useContext, useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { InfoContext } from './Station';

const WLCompareYears = () => {
  const {info,setInfo,selectedStation,setSelectedStation} = useContext(InfoContext)
  const compareYears = info.yearsCompare
  const series = compareYears.years.map(year =>{
    return {
      name: year.name,
      type: 'line',
      smooth: true,
      data: year.data,
      showSymbol: false,
    }
  })
  series[series.length - 1].markLine = {
    symbol: "none",
    data: [{
        name: 'flood level',
        yAxis: info.crossSection.flood_level,
        lineStyle: {
        color: "rgba(146, 29, 29, 1)"
      },
    },{
        name: 'alarm level',
        yAxis: info.crossSection.alarm_level,
        lineStyle: {
        color: "rgba(146, 150, 29, 1)"
      },
    }],
  }
  const option = {
    title: {
      text: 'เทียบข้อมูลย้อนหลัง',
      left: 'center',
          textStyle: {
            fontSize: 16,
            fontFamily: 'Sarabun'
          },
    },
    tooltip: {
      trigger: 'axis'
    },
    legend: {
      data: compareYears.years.map(c=>c.name),
      bottom: 0,
      textStyle: {
        fontSize: 12,
        fontFamily: 'Sarabun'
      },
    },
    grid: {
      left: '5%',
      right: '5%',
      bottom: '15%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      axisLine: { onZero: false },
      data: compareYears.date,
    },
    yAxis: {
      name: 'ระดับน้ำ (ม.รทก.)',
      type: 'value',
      max:info.maxMin.min-2,
      min:(info.station)?info.station.flood_level + 3:null,
    },
    series: series
  };
    return (<ReactECharts option={option} notMerge={true} lazyUpdate={true}  style={{height: '300px', width: '100%'}}/>)};

export default WLCompareYears;