
import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { API_URL } from './Config';

const WLCompare = (props) => {
  const [compareStation, setCompareStation] = useState([[],[]]);

  useEffect(() => {
    fetch(
      `${API_URL}wl/ffw`,
    )
    .then(response => response.json())
    .then(data => setCompareStation(data))

  }, []);
    const option = {
        title: {
          text: '6 สถานีหลัก',
          textStyle: {
            fontSize: 18,
            fontFamily: 'Sarabun'
          },
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: compareStation[1].map(c=>c.name),
          textStyle: {
            fontSize: 14,
            fontFamily: 'Sarabun'
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: compareStation[0],
          name: 'วันที่',
          textStyle: {
            fontSize: 14,
            fontFamily: 'Sarabun'
          }
        },
        yAxis: {
          name: 'ระดับน้ำ (ม.)',
          type: 'value',
        },
        series: compareStation[1]
      };
    return (<ReactECharts option={option} notMerge={true} lazyUpdate={true}  style={{height: '400px', width: '100%'}}/>)};

export default WLCompare;