import { useEffect, useState } from "react";
import ReactECharts from 'echarts-for-react';
import { API_URL } from "./Config";

const DistanceStation = (props) => {

      const option = {
        title: {
          text: 'กราฟแสดงระยะทางระหว่างสถานี (กม.)',
          textStyle: {
            fontSize: 18,
            fontFamily: 'Sarabun'
          },
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // Use axis to trigger tooltip
            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
          },textStyle: {
            fontSize: 16,
            fontFamily: 'Sarabun'
          },
        },
        legend: {
          textStyle: {
            fontSize: 12,
            fontFamily: 'Sarabun'
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value' ,
          name: 'กม.',
        },
        yAxis: {
          type: 'category',
          data: ['จิ่งหง','เชียงแสน','เชียงคาน','หนองคาย','นครพนม','มุกดาหาร','โขงเจียม'],
          
        },
        series: [
          {
            name: 'จิ่งหง',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [null,342,988,1155,1488,1577,1885]
          },
          {
            name: 'เชียงแสน',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [342,null,646,813,1146,1235,1543
            ]
          },
          {
            name: 'เชียงคาน',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [988,646,null,167,500,589,897]
          },
          ,
          {
            name: 'หนองคาย',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [1155,	813,	167,	null,	333,	422,	730
            ]
          },
          {
            name: 'นครพนม',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [1488,	1146,	500,	333,	null	,89,	397]
          },
          {
            name: 'มุกดาหาร',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [1577,	1235	,589	,422,	89	,null	,308
            ]
          },
          
          {
            name: 'โขงเจียม',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [1885	,1543	,897	,730	,397	,308	,null
            ],
          },
        ],
      };
      return (<ReactECharts option={option} notMerge={true} lazyUpdate={true}  style={{height: '400px', width: '100%'}}/>)
}

export default DistanceStation;