import { useContext, useState } from "react"
import { Card, Col, Row } from "react-bootstrap"
import { InfoContext } from "./Station"

const StationBanner = () =>{
    const [styleFont, setStyleFont] = useState({wl:"green",f1:"green"});
    const {info,setInfo,selectedStation,setSelectedStation} = useContext(InfoContext)
    const convertDateShortToTH =(date) => {
        if (date) {
          const date_time = date.split("T");
        const date_arr = date_time[0].split("-");
        const month_list = ["","ม.ค.","ก.พ.","มี.ค.","เม.ย.","พ.ค.","มิ.ย.","ก.ค.","ส.ค.","ก.ย.","ต.ค.","พ.ย.","ธ.ค."]
        const year = parseInt(date_arr[0]) + 543
        const month = month_list[parseInt(date_arr[1])]
        return date_arr[2]+" "+month+" "+year+" เวลา 7.00 น."
        }
        
      }
    return (
        <>
        <Col sm={6} lg={3} className="mb-3">
      <Card>
          <Card.Body>
              <div className="mb-2">
              <Row>
              <Col xs={12}>
                  <Col xs={12}>
                      <div style={{fontSize: '14px'}}>ระดับน้ำ</div>
                  </Col>
                  <Col xs={12}>
                      <b style={{color: styleFont.wl}}>{info.station.value + " ม."}</b>
                  </Col>
              </Col>
              </Row>   
              </div>
              <div style={{fontSize: '12px'}}>{info.station && convertDateShortToTH(info.station.measurement_time)}</div>
          </Card.Body>
      </Card>
  </Col>
  <Col sm={6} lg={3} className="mb-3">
      <Card>
          <Card.Body>
              <div className="mb-2">
              <Row>
              <Col xs={12}>
                  <Col xs={12}>
                      <div style={{fontSize: '14px'}}>ระดับน้ำคาดการณ์ 1 วัน</div>
                  </Col>
                  <Col xs={12}>
                      <b style={{color: styleFont.f1}}>{info.station.f1 + " ม."}</b>
                  </Col>
              </Col>
              </Row>   
              </div>
              <div style={{fontSize: '12px'}}> {info.station && convertDateShortToTH(info.station.measurement_time)}</div>
          </Card.Body>
      </Card>
  </Col>
  <Col sm={6} lg={3} className="mb-3">
      <Card>
          <Card.Body>
              <div className="mb-2">
              <Row>
              <Col xs={12}>
                  <Col xs={12}>
                      <div style={{fontSize: '14px'}}>ปริมาณน้ำ</div>
                  </Col>
                  <Col xs={12}>
                      <b>{(info.rain.today.rain_24)?info.rain.today.rain_24  + " ลบ.ม.":"N/A"}</b>
                  </Col>
              </Col>
              </Row>   
              </div>
              <div style={{fontSize: '12px'}}>{info && convertDateShortToTH(info.rain.today.measurement_time)}</div>
          </Card.Body>
      </Card>
  </Col>
  <Col sm={6} lg={3} className="mb-3">
      <Card>
          <Card.Body>
              <div className="mb-2">
              <Row>
              <Col xs={12}>
                  <Col xs={12}>
                      <div style={{fontSize: '14px'}}>ปริมาณน้ำคาดการณ์ 1 วัน</div>
                  </Col>
                  <Col xs={12}>
                      <b>{(info.rain.yesterday.rain_24)?info.rain.yesterday.rain_24 + " ลบ.ม.":"N/A"}</b>
                  </Col>
              </Col>
              </Row>   
              </div>
              <div style={{fontSize: '12px'}}>{info && convertDateShortToTH(info.rain.yesterday.measurement_time)}</div>
          </Card.Body>
      </Card>
  </Col>
  {/* <Col sm={6} lg={3} className="mb-3">
      <Card>
          <Card.Body>
              <div className="mb-2">
              <Row>
              <Col xs={12}>
                  <Col xs={12}>
                      <div style={{fontSize: '14px'}}>ฝนสะสมวันนี้</div>
                  </Col>
                  <Col xs={12}>
                      <b>{(info.rain.today.rain_24)?info.rain.today.rain_24  + " มม.":"N/A"}</b>
                  </Col>
              </Col>
              </Row>   
              </div>
              <div style={{fontSize: '12px'}}>{info && convertDateShortToTH(info.rain.today.measurement_time)}</div>
          </Card.Body>
      </Card>
  </Col>
  <Col sm={6} lg={3} className="mb-3">
      <Card>
          <Card.Body>
              <div className="mb-2">
              <Row>
              <Col xs={12}>
                  <Col xs={12}>
                      <div style={{fontSize: '14px'}}>ฝนสะสมเมื่อวาน</div>
                  </Col>
                  <Col xs={12}>
                      <b>{(info.rain.yesterday.rain_24)?info.rain.yesterday.rain_24 + " มม.":"N/A"}</b>
                  </Col>
              </Col>
              </Row>   
              </div>
              <div style={{fontSize: '12px'}}>{info && convertDateShortToTH(info.rain.yesterday.measurement_time)}</div>
          </Card.Body>
      </Card>
  </Col> */}
  </>
    )
}

export default StationBanner