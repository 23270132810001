import { Badge, Card, Col, Container, Row, Table } from "react-bootstrap";
import WLCompare from "./WLCompare";
import MiniChart from "./MiniChart";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowTrendDown, faArrowTrendUp, faCoffee, faMapLocationDot } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useState } from "react";
import { API_URL, APP_URL } from "./Config";
import AllStation from "./AllStation";
import DistanceStation from "./DistanceStation";
import TimeTravelFlood from "./TimeTravelFlood";
import TimeTravelDrought from "./TimeTravelDrought";
const Overview = () => {
    const [banners, setBanners] = useState([]);
    
    useEffect(() => {
        fetch(
            `${API_URL}wl/banner`
        )
        .then(response => response.json())
        .then(data => setBanners(data))
    
      }, []);
      const icon = (diff) => {
        if (diff > 0) {
           return faArrowTrendUp
        }else{
            return faArrowTrendDown
        }
      }

      const convertDateToTH =(date) => {
        const date_arr = date.split("-");
        const month_list = ["","มกราคม","กุมภาพันธ์","มีนาคม","เมษายน","พฤษภาคม","มิถุนายน","กรกฎาคม","สิงหาคม","กันยายน","ตุลาคม","พฤศจิกายน","ธันวาคม"]
        const year = parseInt(date_arr[0]) + 543
        const month = month_list[parseInt(date_arr[1])]
        return date_arr[2]+" "+month+" "+year
      }
    return (
        <Container>
            <Row className="mb-3 mt-3" >
                {banners.map((banner)=>{
                    const url = `https://flagcdn.com/w20/${banner.country}.png`
                    return (
                        <Col sm={6} lg={3} className="mb-1" key={banner.station_wl_id}>
                        <Card>
                            {<Card.Body>
                                <Card.Title>
                                  <img src={url} width="20" height="15" style={{marginBottom: '3px'}} /> สถานี{banner.name_th}
                                </Card.Title>
                                <div className="mb-2">
                                <Row>
                                <Col xs={7}>
                                    <Col xs={12}>
                                        <div style={{fontSize: '14px'}}>ระดับน้ำ</div>
                                    </Col>
                                    <Col xs={12}>
                                        <b>{banner.value} ม.</b>
                                        <div><b>({banner.diff} ม.) <FontAwesomeIcon icon={icon(banner.diff)} /></b></div> 
                                    </Col>
                                </Col>
                                <Col xs={5}>
                                        <MiniChart banner={banner}/>
                                </Col>
                                </Row>   
                                </div>
                                <div style={{fontSize: '12px'}}>{convertDateToTH(banner.measurement_time)} เวลา 07.00 น.</div>
                            </Card.Body>}
                        </Card>
                    </Col>
                    )
                })}
                <Col sm={12} lg={12} md={12} className="mb-1">
                    <p style={{textIndent: '20px',color:'darkred'}}><small>* แสดง 4 สถานีแรกที่มีการเปลี่ยนแปลงและในกรณีที่เป็นฤดูแล้ง ข้อมูลจะเปลี่ยนแปลงทุกวันจันทร์</small></p>
                </Col>
            </Row>
            <Row className="mb-3 mt-3" >
                <Col sm={12} lg={12} md={12} className="mb-3">
                    <Card className="mb-3">
                        <Card.Body>
                            <WLCompare />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="mb-3 mt-3" >
                <Col sm={12} lg={12} md={12} className="mb-3">
                    <Card className="mb-3">
                        <Card.Body>
                            <AllStation />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="mb-3 mt-3" >
                <Col sm={12} lg={12} md={12} className="mb-3">
                    <Card className="mb-3">
                        <Card.Body>
                            <DistanceStation />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="mb-3 mt-3" >
                <Col sm={12} lg={12} md={12} className="mb-3">
                    <Card className="mb-3">
                        <Card.Body>
                            <TimeTravelFlood />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="mb-3 mt-3" >
                <Col sm={12} lg={12} md={12} className="mb-3">
                    <Card className="mb-3">
                        <Card.Body>
                            <TimeTravelDrought />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
        
    )
}

export default Overview;