import { useEffect, useState } from 'react'
import Chart from 'react-apexcharts'
import ReactECharts from 'echarts-for-react';
const MiniChart = (props) => {
    const [banner] = useState(props.banner);
    const option = {
      color: ["#003366"],
      tooltip: {
        trigger: 'axis',
      },
      xAxis: {
        data: banner.series.map(s=>s.measurement_time),
        show: false
      },
      yAxis: {
        type: 'value',
        show: false,
        min: Math.min(banner.series.map(s=>s.value)) - 5,
        max:(banner)?banner.flood_level + 3:null,
      },
      series: [
        {
          data: banner.series.map(s=>s.value),
          type: 'line',
          areaStyle: {
            opacity: 0.2
          },
          symbol: 'none',
          smooth: true,
          markLine:{
            symbol: "none",
                data: [{
                    name: 'flood level',
                    yAxis: banner.flood_level,
                    label: {
                      normal: {
                       show: false, 
                      }
                    },
                    lineStyle: {
                    color: "rgba(146, 29, 29, 1)",
                  },
                },{
                    name: 'alarm level',
                    yAxis: banner.alarm_level,
                    label: {
                      normal: {
                       show: false, 
                      }
                    },
                    lineStyle: {
                    color: "rgba(146, 150, 29, 1)"
                  },
                }],
              }
        }
      ]
    };
    
    

      return (<ReactECharts option={option} style={{height: '100%', width: '100%'}}/>)};


export default MiniChart;