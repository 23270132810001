import { Card,Row,Col } from "react-bootstrap";
import MapView from './MapView';
import LineChart from './LineChart';
import React, { useEffect, useState } from "react";
import WLCompareYears from "./WLCompareYears";
import CrossSection from "./CrossSection ";
import StationBanner from "./StationBanner";
import { API_URL } from "./Config";

const InfoContext = React.createContext();
const Station = () => {
  const [info,setInfo] = useState([]);
  const [ts, setTs] = useState([]);
  const [rainWeek,setRainWeek] = useState([]);
  const [stations, setStations] = useState(null);
  const [station, setStation] = useState({value:"N/A",f1:"N/A"});
  const [selectedStation, setSelectedStation] = useState(null);
  const [map, setMap] = useState();
  const [limitGraph,setLimitGraph] = useState([]);
  const [compareYears, setCompareYears] = useState([[],[]]);
  const [crossSection, setCrossSection] = useState({cross_section:[]});
  const [styleFont, setStyleFont] = useState({wl:"green",f1:"green"});
  const [cumulativeRain, setCumulativeRain] = useState([{sum:"N/A"},{sum:"N/A"}]);
  useEffect(() => {
    fetch(
      `${API_URL}wl/last`,
    )
    .then(response => response.json())
    .then(data => setStations(data))

    
  }, []);

  useEffect(() => {
    if (!!selectedStation) {
      fetch(
        `${API_URL}wl/info?station_id=${selectedStation.id}`,
      )
      .then(response => response.json())
      .then(data => {
        data.station = stations.filter(s => s.id == selectedStation.id)[0]
        setInfo(data)
      })
    }
    /* fetch(
      "http://localhost:8000/wl/last"
    )
    .then(response => response.json())
    .then(data => setStations(data))
 */
    
  }, [selectedStation]); 


  /* let onSelectedStation = (selectedStation)=>{
      fetch(
        "http://localhost:8000/wl/info?station_id="+selectedStation.id
      )
      .then(response => response.json())
      .then(data => setInfo(data))

      fetch(
        "http://localhost:8000/wl/month?station_id="+selectedStation.id
      )
      .then(response => response.json())
      .then(data => setTs(data))

      fetch(
        "http://localhost:8000/rain/week?station_id="+selectedStation.id
      )
      .then(response => response.json())
      .then(data => setRainWeek(data))

      fetch(
        "http://localhost:8000/wl/limit?station_id="+selectedStation.id
      )
      .then(response => response.json())
      .then(data => setLimitGraph(data))

      fetch(
        "http://localhost:8000/wl/years-compare?station_id="+selectedStation.id
      )
      .then(response => response.json())
      .then(data => setCompareYears(data))


      fetch(
        "http://localhost:8000/wl/cross-section?station_id="+selectedStation.id
      )
      .then(response => response.json())
      .then(data => setCrossSection(data))

      fetch(
        "http://localhost:8000/rain/station?station_id="+selectedStation.id
      )
      .then(response => response.json())
      .then(data => {
        if (data.length > 0) {
          data.map(r => r.sum = r.sum.toFixed(2))
        setCumulativeRain(data)
        }else{
          setCumulativeRain([{sum:"N/A"},{sum:"N/A"}])
        }
      })

      setStation(stations.filter(s => s.id == selectedStation.id)[0])

  } */
  let onMap = (basemap)=>{
    setMap(basemap)
  }
  return (
    <>
    <InfoContext.Provider value={{info,setInfo,selectedStation,setSelectedStation}}>
<div>
<Row className="mt-2">
  <Col sm={12} lg={5} className="mb-3" style={{paddingRight: '6px'}}>
  <Card body>
    <MapView stations={stations}  map={onMap}/>
    </Card>
  </Col>
  <Col sm={12} lg={7} className="mb-3"  >
  <Row>
      {info.rain && <StationBanner/>}
    {info.crossSection && <Col sm={12} lg={6} style={{paddingRight: '6px',paddingLeft:'6px'}}>
      <Card body className="mb-3">
          <CrossSection/>
      </Card>
    </Col>}
    {info.month && <Col sm={12} lg={6}>
      <Card body className="mb-3">
          <LineChart/>
      </Card>
  </Col>}
  {info.yearsCompare && <Col sm={12} lg={12} >
      <Card body className="mb-3">
          <WLCompareYears/>
      </Card>
  </Col>}
    </Row>
  </Col>
  </Row>
</div>
    </InfoContext.Provider>
    </>
  )
};
export {InfoContext}
export default Station;