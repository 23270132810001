import React from 'react';
import { Link, Outlet,NavLink } from "react-router-dom";
import { Button,Card,Row,Col,Container,Navbar,Nav, Tab, Tabs } from "react-bootstrap";
import Invoices from "./Invoices";
import Station from "./Station";
import Overview from "./Overview";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlassChart, faMapLocationDot } from '@fortawesome/free-solid-svg-icons';

const Layout = () => {
return (
  <>
    <Navbar bg="light" expand="lg">
    <Container fluid>
      <Navbar.Brand href="#" className="my-2 my-lg-0" style={{lineHeight: "45px"}}> <img
            alt=""
            src="/tnmc-logo.png"
            width="50"
            height="50"
            className="d-inline-block align-top"
            style={{marginLeft:"20px",marginRight:"10px"}}
          />สถานการณ์แม่น้ำโขง</Navbar.Brand>
    </Container>
  </Navbar>
  <Nav variant="tabs"  defaultActiveKey="/">
      <Nav.Item>
        <NavLink to="/" className={"nav-link"}><FontAwesomeIcon icon={faMagnifyingGlassChart} /> ภาพรวม</NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink to="station" className={"nav-link"}><FontAwesomeIcon icon={faMapLocationDot} /> สถานีตรวจวัด</NavLink>
      </Nav.Item>
    </Nav>
  </>
)
}

export default Layout;