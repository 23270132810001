
import React, { useContext } from 'react';
import ReactECharts from 'echarts-for-react';
import moment from 'moment';
import { InfoContext } from './Station';

const LineChart = (props) => {
    const {info,setInfo,selectedStation,setSelectedStation} = useContext(InfoContext)
    const ts = info.month
    const convertDateShortToTH =(date) => {
      const date_time = date.split(" ");
      const date_arr = date_time[0].split("-");
      const month_list = ["","ม.ค.","ก.พ.","มี.ค.","เม.ย.","พ.ค.","มิ.ย.","ก.ค.","ส.ค.","ก.ย.","ต.ค.","พ.ย.","ธ.ค."]
      const year = parseInt(date_arr[0]) + 543
      const month = month_list[parseInt(date_arr[1])]
      return date_arr[2]+" "+month+" "+year+"\n"+date_time[1]
    }
    
    const option = /* {
        title: {
          text: info.name_th,
          left: 'center',
          textStyle: {
            fontSize: 16,
            fontFamily: 'Sarabun'
          },
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            animation: false,
            label: {
              backgroundColor: '#505765'
            }
          }
        },
        legend: {
          data: ['ระดับน้ำ', 'ปริมาณน้ำฝนสะสม 24 ชม.'],
          bottom: 0,
          textStyle: {
            fontSize: 12,
            fontFamily: 'Sarabun'
          },
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            axisLine: { onZero: false },
            // prettier-ignore
            data: ts.map(t => convertDateShortToTH(moment(t.tem_date).format('YYYY-MM-DD HH:mm')))
          }
        ],
        yAxis: [
          {
            name: 'ระดับน้ำ(ม.รทก.)',
            type: 'value',
            max:info.maxMin.min-2,
            min:(info.station)?info.station.flood_level + 3:null,
          },
          {
            name: 'ปริมาณฝน(มม.)',
            nameLocation: 'start',
            alignTicks: true,
            type: 'value',
            inverse: true,
            max:180,
            min:0,
          }
        ],
        series: [
          {
            name: 'ระดับน้ำ',
            type: 'line',
            areaStyle: {},
            lineStyle: {
              width: 1
            },
            emphasis: {
              focus: 'series'
            },
            data: ts.map(t => t.wl),
            showSymbol: false,
          },
          {
            name: 'ปริมาณน้ำฝนสะสม 24 ชม.',
            type: 'bar',
            yAxisIndex: 1,
            areaStyle: {},
            lineStyle: {
              width: 1
            },
            emphasis: {
              focus: 'series'
            },
            data: ts.map(t => t.rain),
            showSymbol: false,
          }
        ]
      } */
      {
        title: {
          text: info.name_th,
          left: 'center',
          textStyle: {
            fontSize: 16,
            fontFamily: 'Sarabun'
          },
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            animation: false,
            label: {
              backgroundColor: '#505765'
            }
          }
        },
        legend: {
          data: ['ระดับน้ำ', 'ปริมาณน้ำฝนสะสม 24 ชม.'],
          bottom: 0,
          textStyle: {
            fontSize: 12,
            fontFamily: 'Sarabun'
          },
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            axisLine: { onZero: false },
            // prettier-ignore
            data: ts.map(t => convertDateShortToTH(moment(t.tem_date).format('YYYY-MM-DD HH:mm')))
          }
        ],
        yAxis: [
          {
            name: 'ระดับน้ำ (ม.)',
            type: 'value',
            max:info.maxMin.min-2,
            min:(info.station)?info.station.flood_level + 3:null,
          },
        ],
        series: [
          {
            name: 'ระดับน้ำ',
            type: 'line',
            areaStyle: {},
            lineStyle: {
              width: 1
            },
            emphasis: {
              focus: 'series'
            },
            data: ts.map(t => t.wl),
            showSymbol: false,
          },
        ]
      }
        option.series[0].markLine = {
          symbol: "none",
              data: [{
                  name: 'flood level',
                  yAxis: (info.station)?info.station.flood_level:null,
                  label: {
                    normal: {
                     show: false, 
                    }
                  },
                  lineStyle: {
                  color: "rgba(146, 29, 29, 1)",
                },
              },{
                  name: 'alarm level',
                  yAxis: (info.station)?info.station.alarm_level:null,
                  label: {
                    normal: {
                     show: false, 
                    }
                  },
                  lineStyle: {
                  color: "rgba(146, 150, 29, 1)"
                },
              }],
            }
      
        
    return (<ReactECharts option={option} notMerge={true} lazyUpdate={true}  style={{height: '300px', width: '100%'}}/>)};

export default LineChart;