import logo from './logo.svg';
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import './App.css';
import Layout from './pages/Layout';
import Overview from './pages/Overview';
import Station from './pages/Station';

function App() {
  return (
    <BrowserRouter>
      <Layout/>
    <Routes>
      <Route path="/" element={<Overview/>}></Route>
       <Route path="station" element={<Station />} /> 
    </Routes>
  </BrowserRouter>
  );
}

export default App;
