import React ,{useState,useEffect,useRef, useContext} from 'react';
import { Marker, Popup } from 'react-leaflet'
import { Icon } from 'leaflet';
import _ from 'lodash';
import { InfoContext } from './Station';

const CustomMarker = (props) => {
  const data = props.data
  const map = props.map
/*   const iconUrl = "/icon/signal-tower.png";
  const newicon = new Icon({
    iconUrl,
    iconAnchor: [5, 5],
    popupAnchor: [10, 10],
    iconSize: [25, 25]
  }); */
  const icon = (iconUrl) =>{
   return new Icon({
    iconUrl,
      iconAnchor: [5, 5],
      popupAnchor: [10, 10],
      iconSize: [25, 25]
    });
  }
  const stations = data;
  const locations = _.map(stations,(station)=> {
    let iconUrl = "/icon/signal-tower-green.png"
    //let iconUrl = "/icon/signal-tower-yellow.png"
    if (station.value >= station.alarm_level) {
      iconUrl = "/icon/signal-tower-yellow.png"
    }
    if(station.value >= station.flood_level){
      iconUrl = "/icon/signal-tower-red.png"
    }
    return {id:station.id,name_th:station.name_th,location:[station.latitude,station.longitude],country:station.country,url:iconUrl}
  })
/*   console.log(locations); */
  const {info,setInfo,selectedStation,setSelectedStation} = useContext(InfoContext)
  let markerRefs = useRef();
  const markerClick = (station)=>{
    setSelectedStation(station)
    map.flyTo(station.location, map.getZoom())
    markerRefs[station.id].openPopup()
  }
  useEffect(()=> {
    markerClick(locations[3])
  },[])
    return (
      <>
      {locations.map((station,idx)=>{
        const url = `https://flagcdn.com/w20/${station.country}.png`.toLowerCase();
        return(
          <Marker position={station.location} icon={icon(station.url)}  key={station.id}
            ref={(r) => {
              markerRefs[station.id] = r
            }
          } eventHandlers={{
            click: () => markerClick(station),
          }}
        >
            <Popup style={{height: '400px', width: '400px'}}>
              <div> {info.crossSection &&<img src={url} width="20" height="15" style={{marginBottom: '3px'}} />} สถานี{station.name_th}</div>
            </Popup>
          </Marker>
         );
      })}
      </>
    );
  };
  export default CustomMarker;