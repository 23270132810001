import { useEffect, useState } from "react";
import ReactECharts from 'echarts-for-react';
import { API_URL } from "./Config";

const TimeTravelDrought = (props) => {
    

    useEffect(() => {
    /*   fetch(
        `${API_URL}wl/ffw`,
      )
      .then(response => response.json())
      .then(data => {setCompareStation(data)
        console.log(data);
    }) */
        
    }, []);

      const option = {
        title: {
          text: 'ระยะเวลาน้ำเดินทาง ฤดูแล้ง (ชม.)',
          textStyle: {
            fontSize: 18,
            fontFamily: 'Sarabun'
          },
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // Use axis to trigger tooltip
            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
          },textStyle: {
            fontSize: 16,
            fontFamily: 'Sarabun'
          },
        },
        legend: {
          textStyle: {
            fontSize: 12,
            fontFamily: 'Sarabun'
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          name: 'ชม.'
        },
        yAxis: {
          type: 'category',
          data: ['จิ่งหง','เชียงแสน','เชียงคาน','หนองคาย','นครพนม','มุกดาหาร','โขงเจียม']
        },
        series: [
          {
            name: 'จิ่งหง',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [null,	24	,168	,216	,288	,312	,360
            ]
          },
          {
            name: 'เชียงแสน',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [24,	null	,144	,192,	264,	288	,336
            ]
          },
          {
            name: 'เชียงคาน',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [168	,144,	null	,48	,120	,144,	192
            ]
          },
          ,
          {
            name: 'หนองคาย',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [216	,192	,48,	null	,72	,96,	144
            ]
          },
          {
            name: 'นครพนม',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [288,	264,	120	,72,	null	,24	,72
            ]
          },
          {
            name: 'มุกดาหาร',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [312,	288	,144	,96	,24,	null,48
            ]
          },
          
          {
            name: 'โขงเจียม',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [360,	336	,192,	144	,72,	24,	null
            ]
          },
        ]
      };
      return (<ReactECharts option={option} notMerge={true} lazyUpdate={true}  style={{height: '400px', width: '100%'}}/>)
}

export default TimeTravelDrought;