import { MapContainer, TileLayer  } from 'react-leaflet'
import "leaflet/dist/leaflet.css";
import ReactLeafletKml from 'react-leaflet-kml';
import CustomMarker from './CustomMarker';
import React, { useState,useEffect }  from 'react';

const MapView = (props) => {
const position = [18.685844024728723, 103.59193195411508] 

const stations = props.stations;
const selectedStation = props.selectedStation;
//setSelectedStation(props.selectedStation)
const [kml, setKml] = useState(null);
const [map, setMap] = useState();
  useEffect(() => {
    fetch(
      "/kmz/mekhong.kml"
    )
      .then((res) => res.text())
      .then((kmlText) => {
        const parser = new DOMParser();
        const kml = parser.parseFromString(kmlText, "text/xml");
        setKml(kml);
      });
      props.map(map)
  }, []);
  
return(

  <MapContainer center={position} zoom={7} scrollWheelZoom={true} style={{ width: '100%', height: '80vh'}} ref={setMap}>
    
    <TileLayer  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
/>
{map && stations && props && <CustomMarker map={map} data={stations}  isActive></CustomMarker>}
    {kml && <ReactLeafletKml kml={kml} />}
  </MapContainer>
)}


export default MapView;